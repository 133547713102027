import router from '@/router'
import { useSnackbarStore } from '@/stores/snackbar'
import type { TransportationDetailedDTO } from '@/types/api/Transportation/TransportationDetailedDTO'
import type {
  ApproveDriverRequestResponse,
  CreateCarrierRequestPayload,
  CreateCarrierRequestResponse,
  CreateDriverRequestPayload,
  CreateDriverRequestResponse,
  GetAccountInfoResponse,
  GetAvailableCitizenshipsResponse,
  GetAvailableTransportationStatuses,
  GetAvailableVehicleModelsResponse,
  GetCarrierDetailedResponse,
  GetCarrierRequestsResponse,
  GetCarriersResponse,
  GetDriverDetailedResponse,
  GetDriverRequestPayload,
  GetDriverRequestsResponse,
  GetDriversResponse,
  GetPartnersResponse,
  GetVehiclesResponse,
  RejectDriverRequestResponse,
  UpdatePartnerPayload,
  UpdatePartnerResponse
} from '@/types/api/requests'
import type { APIResponse } from '@/types/api/requests/APIResponse'
import type {
  ApproveCarrierRequestResponse,
  GetCarrierRequestPayload,
  RejectCarrierRequestResponse
} from '@/types/api/requests/Carriers'
import type { GetDriverResponse } from '@/types/api/requests/Drivers'
import type {
  ApproveDriverResponse,
  AssignDriverResponse,
  CancelDriverResponse,
  ExcludeFromSpreadingResponse,
  IncludeInSpreadingResponse,
  SearchTransporationsResponse,
  SearchTransportationsPayload
} from '@/types/api/requests/Transportations'
import type {
  ApproveVehicleRequestResponse,
  CreateVehicleRequestPayload,
  CreateVehicleRequestResponse,
  GetVehicleDetailedResponse,
  GetVehicleRequestPayloadResponse,
  GetVehicleRequestsResponse,
  GetVehicleResponse,
  RejectVehicleRequestResponse
} from '@/types/api/requests/Vehicles'
import { AuthService } from '@/utils/auth.service'
import { getErrorMessage } from '@/utils/helpersLegacy'
import { captureException } from '@sentry/vue'
import axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'

const apiInstance = axios.create({
  baseURL: import.meta.env.VITE_VUE_APP_API_URL as string
})

/**
 * This is a workaround for the handling multiple requests at the same time
 * The reference to the refresh token promise is stored and then resolved for all of the parallel requests
 */
let refreshTokenPromise: Promise<string | null> | null = null
const authService = new AuthService()

apiInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (!refreshTokenPromise) {
      refreshTokenPromise = authService.getValidAccessToken().then((token) => {
        refreshTokenPromise = null
        return token
      })
    }

    return refreshTokenPromise.then((token) => {
      config.headers.Authorization = token
      return config
    })
  },
  function (error) {
    console.log('Rejected Request:')
    console.log(error)
    refreshTokenPromise = null
  }
)

apiInstance.interceptors.response.use(
  async function (response: AxiosResponse<APIResponse>) {
    if (response.data.error?.code === 401) {
      await router.replace('/auth')
    } else if (response.data.error) {
      captureException(response.data.error)
      const snackbarStore = useSnackbarStore()
      snackbarStore.openSnackbar({
        color: 'red',
        message: getErrorMessage(response.data.error)
      })
      response.data.data = null
    }
    return response
  },
  function (error) {
    console.log('Rejected Response:')
    console.log(error)
  }
)

export default apiInstance

export const apiService = {
  getAvailableCitizenShips: async () => {
    const response = await apiInstance.get<APIResponse<GetAvailableCitizenshipsResponse>>(
      'dictionaries/citizenships'
    )
    return response.data.data
  },
  getAccountInfo: async () => {
    const response = await apiInstance.get<APIResponse<GetAccountInfoResponse>>('accounts/info')
    return response.data.data
  },
  getPartners: async () => {
    const response = await apiInstance.get<APIResponse<GetPartnersResponse>>('partners')
    return response.data.data
  },

  getVehicle: async (id: number) => {
    const response = await apiInstance.get<APIResponse<GetVehicleResponse>>(`vehicles/${id}`)
    return response.data.data
  },
  getVehicles: async () => {
    const response = await apiInstance.get<APIResponse<GetVehiclesResponse>>('vehicles')
    return response.data.data
  },
  getVehicleDetailed: async (id: number) => {
    const response = await apiInstance.get<APIResponse<GetVehicleDetailedResponse>>(
      `vehicles/${id}/detailed`
    )
    return response.data.data
  },
  getDriver: async (id: number) => {
    const response = await apiInstance.get<APIResponse<GetDriverResponse>>(`drivers/${id}`)
    return response.data.data
  },
  getDrivers: async (inSpreading: boolean) => {
    const response = await apiInstance.get<APIResponse<GetDriversResponse>>('drivers', {
      params: {
        inSpreading
      }
    })
    return response.data.data
  },
  getDriverDetailed: async (driverId: number) => {
    const response = await apiInstance.get<APIResponse<GetDriverDetailedResponse>>(
      `drivers/${driverId}/detailed`
    )
    return response.data.data
  },
  includeDriverInSpreading: async (driverId: number) => {
    const response = await apiInstance.post<APIResponse>(`drivers/${driverId}/include-spreading`)
    return response.data.data
  },
  excludeDriverFromSpreading: async (driverId: number, excludeTimeInMinutes: number) => {
    const response = await apiInstance.post<APIResponse>(`drivers/${driverId}/exclude-spreading`, {
      excludeTimeInMinutes
    })
    return response.data.data
  },

  getDriverRequests: async () => {
    const response =
      await apiInstance.get<APIResponse<GetDriverRequestsResponse>>('driver-requests')
    return response.data.data
  },
  getDriverRequestPayload: async (driverId: number) => {
    const response = await apiInstance.get<APIResponse<GetDriverRequestPayload>>(
      'driver-requests/payload',
      {
        params: { driverId }
      }
    )
    return response.data.data
  },
  getTransporationDetailed: async (transporationId: number) => {
    const response = await apiInstance.get<APIResponse<TransportationDetailedDTO>>(
      `transportations/${transporationId}/detailed`
    )
    return response.data.data
  },
  getVehicleRequests: async () => {
    const response =
      await apiInstance.get<APIResponse<GetVehicleRequestsResponse>>('vehicle-requests')
    return response.data.data
  },
  getVehicleRequestPayload: async (vehicleId: number) => {
    const response = await apiInstance.get<APIResponse<GetVehicleRequestPayloadResponse>>(
      'vehicle-requests/payload',
      {
        params: { vehicleId }
      }
    )
    return response.data.data
  },
  getAvailableVehicleModels: async () => {
    const response = await apiInstance.get<APIResponse<GetAvailableVehicleModelsResponse>>(
      'dictionaries/vehicle-models'
    )
    return response.data.data
  },
  getAvailableTransportationStatuses: async () => {
    const response = await apiInstance.get<APIResponse<GetAvailableTransportationStatuses>>(
      'dictionaries/transportation-statuses'
    )
    return response.data.data
  },

  updatePartner: async (id: number, data: UpdatePartnerPayload) => {
    const response = await apiInstance.patch<APIResponse<UpdatePartnerResponse>>(
      `partners/${id}`,
      data
    )
    return response.data.data
  },
  takeDriverOffline: async (driverId: number) => {
    const response = await apiInstance.post<APIResponse>(`drivers/${driverId}/take-off`)
    return response.data.data
  },
  createDriverRequest: async (withApproval: boolean, payload: CreateDriverRequestPayload) => {
    const path = withApproval ? 'driver-requests/approve' : 'driver-requests'
    const response = await apiInstance.post<APIResponse<CreateDriverRequestResponse>>(path, payload)
    return response.data.data
  },
  approveDriverRequest: async (id: number) => {
    const response = await apiInstance.post<APIResponse<ApproveDriverRequestResponse>>(
      `driver-requests/${id}/approve`
    )
    return response.data.data
  },
  rejectDriverRequest: async (id: number, comment: string) => {
    const response = await apiInstance.post<APIResponse<RejectDriverRequestResponse>>(
      `driver-requests/${id}/reject`,
      { comment }
    )
    return response.data.data
  },
  searchTransportations: async (payload: SearchTransportationsPayload) => {
    const response = await apiInstance.post<APIResponse<SearchTransporationsResponse>>(
      'transportations/search',
      payload
    )
    return response.data.data
  },
  assignDriver: async (transportationId: number, driverId: number, vehicleId: number) => {
    const response = await apiInstance.post<APIResponse<AssignDriverResponse>>(
      `transportations/${transportationId}/assign-driver`,
      {
        driverId,
        vehicleId
      }
    )
    return response.data.data
  },
  cancelDriver: async (transportationId: number, driverId: number) => {
    const response = await apiInstance.post<APIResponse<CancelDriverResponse>>(
      `transportations/${transportationId}/cancel-driver`,
      {
        driverId
      }
    )
    return response.data.data
  },
  excludeTransportationFromSpreading: async (transportationId: number) => {
    const response = await apiInstance.post<APIResponse<ExcludeFromSpreadingResponse>>(
      `transportations/${transportationId}/exclude-spreading`,
      undefined
    )
    return response.data.data
  },
  includeTransportationInSpreading: async (transportationId: number) => {
    const response = await apiInstance.post<APIResponse<IncludeInSpreadingResponse>>(
      `transportations/${transportationId}/include-spreading`,
      undefined
    )
    return response.data.data
  },
  approveDriver: async (transportationId: number) => {
    const response = await apiInstance.post<APIResponse<ApproveDriverResponse>>(
      `transportations/${transportationId}/approve-driver`,
      undefined
    )
    return response.data.data
  },
  rejectDriver: async (transportationId: number) => {
    const response = await apiInstance.post<APIResponse<ApproveDriverResponse>>(
      `transportations/${transportationId}/reject-driver`,
      undefined
    )
    return response.data.data
  },
  createVehicleRequest: async (withApproval: boolean, payload: CreateVehicleRequestPayload) => {
    const path = withApproval ? 'vehicle-requests/approve' : 'vehicle-requests'
    const response = await apiInstance.post<APIResponse<CreateVehicleRequestResponse>>(
      path,
      payload
    )
    return response.data.data
  },
  approveVehicleRequest: async (id: number) => {
    const response = await apiInstance.post<APIResponse<ApproveVehicleRequestResponse>>(
      `vehicle-requests/${id}/approve`
    )
    return response.data.data
  },
  rejectVehicleRequest: async (id: number, comment: string) => {
    const response = await apiInstance.post<APIResponse<RejectVehicleRequestResponse>>(
      `vehicle-requests/${id}/reject`,
      { comment }
    )
    return response.data.data
  },
  getCarriers: async (partnerId?: number) => {
    const response = await apiInstance.get<APIResponse<GetCarriersResponse>>('carriers', {
      params: { partnerId }
    })
    return response.data.data
  },
  getCarrierDetailed: async (id: number) => {
    const response = await apiInstance.get<APIResponse<GetCarrierDetailedResponse>>(
      `carriers/${id}/detailed`
    )
    return response.data.data
  },
  getCarrierRequests: async () => {
    const response =
      await apiInstance.get<APIResponse<GetCarrierRequestsResponse>>('carrier-requests')
    return response.data.data
  },
  createCarrierRequest: async (payload: CreateCarrierRequestPayload, withApproval: boolean) => {
    const path = withApproval ? 'carrier-requests/approve' : 'carrier-requests'
    const response = await apiInstance.post<APIResponse<CreateCarrierRequestResponse>>(
      path,
      payload
    )
    return response.data.data
  },
  getCarrierRequestPayload: async (carrierId: number) => {
    const response = await apiInstance.get<APIResponse<GetCarrierRequestPayload>>(
      'carrier-requests/payload',
      {
        params: { carrierId }
      }
    )
    return response.data.data
  },
  approveCarrierRequest: async (id: number) => {
    const response = await apiInstance.post<APIResponse<ApproveCarrierRequestResponse>>(
      `carrier-requests/${id}/approve`
    )
    return response.data.data
  },
  rejectCarrierRequest: async (id: number, comment: string) => {
    const response = await apiInstance.post<APIResponse<RejectCarrierRequestResponse>>(
      `carrier-requests/${id}/reject`,
      { comment }
    )
    return response.data.data
  },
  uploadFile: async (file: File) => {
    if (file.size > 20 * 1024 * 1024) {
      const snackbarStore = useSnackbarStore()
      snackbarStore.openSnackbar({
        color: 'red',
        message: 'Размер файла не должен превышать 20 МБ'
      })
      return null
    }
    const formData = new FormData()
    formData.append('file', file)
    const response = await apiInstance.post<APIResponse<{ id: string; name: string }>>(
      'files',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return response.data.data
  },
  downloadFile: async (fileId: string, fileName: string) => {
    const response = await apiInstance.get<Blob>(`files/${fileId}`, {
      responseType: 'blob'
    })
    const fileBlob: Blob = new Blob([response.data])
    const downloadUrl: string = URL.createObjectURL(fileBlob)
    const link: HTMLAnchorElement = document.createElement('a')

    link.href = downloadUrl
    link.download = fileName

    document.body.appendChild(link)
    link.click()

    URL.revokeObjectURL(downloadUrl)
    document.body.removeChild(link)
    return response
  },

  retrieveFileForPreview: async (fileId: string) => {
    const response = await apiInstance.get<Blob>(`files/${fileId}`, {
      responseType: 'blob'
    })
    const fileBlob: Blob = new Blob([response.data])
    const file = new File([fileBlob], fileId, {
      type: response.headers['content-type']
    })

    return file
  }
}
